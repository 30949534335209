export const treatmentQuestions = [
    {
        question: "Na jakie aspekty zwraca się uwagę przy leczeniu rzutowo-remisyjnej postaci SM?",
        option1: "leczenie rzutów",
        option2: "modyfikację przebiegu choroby",
        option3: "rehabilitację",
        option4: "wszystkie odpowiedzi są poprawne",
        ans: 4,
    },
    {
        question: "Jak nazywa się lek, który jest podawany w kroplówce przy wystąpieniu rzutu SM, w celu złagodzenia objawów choroby?",
        option1: "Solu-Medrol",
        option2: "Extavia",
        option3: "Kesimpta",
        option4: "Ocrevus",
        ans: 1,
    },
    {
        question: "Ile jest linii leczenia RRMS w Polsce?",
        option1: "jedna",
        option2: "dwie",
        option3: "trzy",
        option4: "cztery",
        ans: 2,
    },
    {
        question: "Które z poniższych leków należą do pierwszej linii leczenia?",
        option1: "Kesimpta i Tecfidera",
        option2: "Zeposia i Lemtrada",
        option3: "Gilenya i Tysabri",
        option4: "Tysabri i Kesimpta",
        ans: 1,
    },
    {
        question: "Które z poniższych leków należą do drugiej linii leczenia?",
        option1: "Mavenclad i Avonex",
        option2: "Zeposia i Aubagio",
        option3: "Gilenya i Tysabri",
        option4: "Kesimpta i Mavenclad",
        ans: 3,
    },
    {
        question: "Rehabilitacja ruchowa pomaga między innymi przy:",
        option1: "poprawie równowagi",
        option2: "wzmocnieniu mięśni",
        option3: "zmniejszaniu spastyczności",
        option4: "wszystkie odpowiedzi są prawidłowe",
        ans: 4,
    }
]