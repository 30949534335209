const AccDeclaration = () => {
    return(
        <>
            <div className="headDiv">
                <h1 className="mainTitle">Deklaracja Dostępności dla strony internetowej www.rrms.pl</h1>
            </div>

            <div className="contentDiv punktor-dec">
                <p id="a11y-wstep">
                    <span id="a11y-podmiot">RRMS.pl</span> zobowiązuje się zapewnić dostępność swojej strony internetowej zgodnie z przepisami ustawy z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych. Oświadczenie w sprawie dostępności ma zastosowanie do
                    serwisu www.rrms.pl.</p>
                <ul>
                    <li>Data publikacji strony internetowej:
                        <span id="a11y-data-publikacja"> 2024-08-27</span>.</li>
                    <li>Data ostatniej istotnej aktualizacji:
                        <span id="a11y-data-aktualizacja"> 2024-08-27</span>.</li>
                </ul>
                <section>
                    <h2>Status zgodności z ustawą</h2>
                    <p id="a11y-status">
                        <strong>Strona internetowa jest częściowo zgodna</strong>  z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych z powodu niezgodności lub wyłączeń wymienionych poniżej.</p>
                    <h3>Niespełnione wymagania</h3>
                    <ul>
                        <li>Brak możliwości wyświetlania bardzo dużych liter.</li>
                    </ul>
                    <h3>Powody braku spełaniania wymagań</h3>
                    <ul>
                        <li>Czcionka w obrębie witryny nie jest mniejsza niż 18px.</li>
                    </ul>
                </section>
                <section>
                    <h2>Przygotowanie deklaracji dostępności</h2>
                    <p>Oświadczenie sporządzono dnia:
                        <span id="a11y-data-sporzadzenie"> 2024-08-27</span>.</p>
                    <p>Deklarację sporządzono na podstawie samooceny przeprowadzonej przez podmiot publiczny.</p>
                    <p>Data ostatniego przeglądu deklaracji:
                        <span id="a11y-data-przeglad"> 2024-08-27</span>.</p>
                </section>
                <section>
                    <h2>Skróty klawiaturowe</h2>
                    <ul>
                        <li>Na stronie internetowej można korzystać ze standardowych skrótów klawiaturowych przeglądarki.</li>
                    </ul>
                </section>
                <section>
                    <h2 id="a11y-kontakt">Informacje zwrotne i dane kontaktowe</h2>
                    <p>W przypadku problemów z dostępnością strony internetowej prosimy o kontakt.</p>
                    <section>
                        <h3>Obsługa wniosków i skarg związanych z dostępnością</h3>
                        <p id="a11y-procedura">Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich elementu. Można także zażądać udostępnienia informacji za pomocą alternatywnego sposobu dostępu, na przykład przez odczytanie niedostępnego cyfrowo dokumentu, opisanie zawartości filmu bez audiodeskrypcji itp. Żądanie powinno zawierać dane osoby zgłaszającej żądanie, wskazanie, o którą stronę internetową lub aplikację mobilną chodzi oraz sposób kontaktu. Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji za pomocą alternatywnego sposobu dostępu, powinna także określić dogodny dla niej sposób przedstawienia tej informacji. Podmiot publiczny powinien zrealizować żądanie niezwłocznie, nie później niż w ciągu 7 dni od dnia wystąpienia z żądaniem. Jeżeli dotrzymanie tego terminu nie jest możliwe, podmiot publiczny niezwłocznie informuje o tym wnoszącego żądanie, kiedy realizacja żądania będzie możliwa, przy czym termin ten nie może być dłuższy niż 2 miesiące od dnia wystąpienia z żądaniem. Jeżeli zapewnienie dostępności cyfrowej nie jest możliwe, podmiot publiczny może zaproponować alternatywny sposób dostępu do informacji. W przypadku, gdy podmiot publiczny odmówi realizacji żądania zapewnienia dostępności lub alternatywnego sposobu dostępu do informacji, wnoszący żądanie możne złożyć skargę w sprawie zapewniana dostępności cyfrowej strony internetowej, aplikacji mobilnej lub elementu strony internetowej, lub aplikacji mobilnej. Po wyczerpaniu wskazanej wyżej procedury można także złożyć wniosek do Rzecznika Praw Obywatelskich.</p>
                        <p>
                            <a href="https://www.rpo.gov.pl/content/jak-zglosic-sie-do-rzecznika-praw-obywatelskich" id="rpo-link">Strona internetowa Rzecznika Praw Obywatelskich</a>
                        </p>
                    </section>
                </section>
                <section>
                    <h2 id="a11y-aplikacje">Aplikacje mobilne</h2>
                    <p>brak</p>
                </section>


            </div>
        </>
    );
}

export default AccDeclaration;