import "./index.css";
import fotoQuestions from "./img/pytajniki.jpg";
import fourPeople from "./img/four-people.png";
import fotoDetective from "./img/detective.png";

const Home = () => {
    return(
        <>
            <div className="headDiv">
                <h1 className="mainTitle">RRMS.pl</h1>
                <h2 className="mainSubtitle">Twój przewodnik po rzutowo-remisyjnej postaci stwardnienia rozsianego</h2>
            </div>
            <div className="contentDiv">
                <div className="contentDivTitle">
                    <h3>Czym jest RRMS?</h3>
                </div>
                <div className="contentDivText">
                    <img src={fotoQuestions} alt="szkic twarzy a nad nią trzy pytajniki" id="fotoQuestions"/>
                    <p>RRMS, czyli rzutowo-remisyjna postać stwardnienia rozsianego jest najczęściej występującą formą tej choroby.
                        Charakteryzuje się ona występowaniem rzutów, czyli okresów, w których objawy SM ulegają nagłemu pogorszeniu.
                        Po nich następują okresy remisji, w trakcie których objawy całkowicie ustępują lub też utrzymują się na stałym poziomie.
                        RRMS jest początkowym etapem u większości osób ze stwardnieniem rozsianym <a className="source" href="/literatura">[1]</a>.</p>
                </div>
                <div className="line"></div>
            </div>

            <div className="contentDiv">
                <div className="contentDivTitle">
                    <h3>Dla kogo jest ten przewodnik?</h3>
                </div>
                <div className="contentDivText">
                    <img src={fourPeople} alt="czarne sylwetki czterech cieszących się osób" id="four-people"/>
                    <p>Przewodnik ten przeznaczony jest dla osób chorych na tę formę choroby,
                        ich rodzin oraz osób chcących zgłębić tematykę rzutowo-remisyjnej postaci stwardnienia rozsianego.
                        Ma on na celu dostarczenie rzetelnych informacji, które pomogą zrozumieć RRMS oraz przyswoić wiedzę w przystępny sposób.</p>
                </div>
                <div className="line"></div>
            </div>

            <div className="contentDiv">
                <div className="contentDivTitle">
                    <h3>Co tutaj znajdziesz?</h3>
                </div>
                <div className="contentDivText">
                    <img src={fotoDetective} alt="rysunek detektywa patrzącego przez lupę na pytajnik" id="fotoDetective"/>
                    <p>W tym przewodniku internetowym znajdziesz najważniejsze informacje dotyczące objawów oraz możliwości
                        leczenia rzutowo-remisyjnej postaci stwardnienia rozsianego. Treści te pomogą Ci zrozumieć, m.in. jak przebiega choroba,
                        jakie są jej symptomy oraz jakie leczenie może ci pomóc w walce z objawami i spowolni przebieg choroby.
                        W sekcji „styl życia” znajdziesz wskazówki dotyczące codziennej aktywności, diety oraz radzenia sobie ze stresem.
                        Natomiast mapa specjalistów przedstawia placówki medyczne, które przeznaczone są dla osób zmagających się z RRMS.</p>
                </div>
            </div>
        </>


    );
}

export default Home;