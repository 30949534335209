import "../index.css";
import neuron from "../img/neuron-foto.jpg";

const Symptoms = () => {
    return (
        <>
            <div className="headDiv">
                <h1 className="mainTitle">Objawy RRMS</h1>
            </div>

            <div className="contentDiv">
                <div className="contentDivText">
                    <img src={neuron} alt="abstakcyjne zdjęcie neuronu" id="neuron-foto"/>
                    <p>
                        Rzutowo-remisyjna postać stwardnienia rozsianego (ang. <i>relapsing-remitting multiple
                        sclerosis, RRMS</i>)
                        jest najczęstszą formą tej choroby, na którą choruje około 80-85% zdiagnozowanych pacjentów <a
                        className="source" href="/literatura">[1]</a>.
                        Charakteryzuje się ona atakami (inaczej rzutami) objawów natury neurologicznej.
                        Po nich pojawia się remisja, czyli okresy częściowego lub też całkowitego powrotu do zdrowia <a
                        className="source" href="/literatura">[2]</a>.
                        <br/><br/>
                        RRMS charakteryzuje się występowaniem charakterystycznych symptomów, które utrzymują się dłużej
                        niż 24 godziny.
                        Kluczowa jest w tym przypadku uważna obserwacja organizmu i nieignorowanie objawów, ponieważ
                        mogą one świadczyć o
                        zachodzących w ośrodkowym układzie nerwowym nieodwracalnych zmianach <a className="source"
                                                                                                href="/literatura">[3]</a>.
                        <br/><br/>
                        Najczęstszymi objawami, które występują podczas rzutu w min. 50% przypadków są <a
                        className="source" href="/literatura">[4]</a>:
                        <ul>
                            <li>zmęczenie;</li>
                            <li>zaburzenia równowagi;</li>
                            <li>drętwienie kończyn;</li>
                            <li>trudności w zapamiętywaniu;</li>
                            <li>ból.</li>
                        </ul>
                        Poza nimi mogą wystąpić również <a className="source" href="/literatura">[4]</a>:
                        <ul>
                            <li>skurcze mięśni;</li>
                            <li>problemy z koncentacją;</li>
                            <li>problemy ze wzrokiem;</li>
                            <li>nietrzymanie moczu;</li>
                            <li>depresja;</li>
                            <li>sztywność kończyn;</li>
                            <li>zawroty głowy;</li>
                            <li>zaparcia;</li>
                            <li>wahania nastroju;</li>
                            <li>drażliwość;</li>
                            <li>problemy natury seksualnej;</li>
                            <li>problemy z mówieniem;</li>
                            <li>drżenie ciała;</li>
                            <li>swędzenie;</li>
                            <li>problemy z połykaniem;</li>
                            <li>utrata słuchu;</li>
                            <li>problemy z oddychaniem;</li>
                            <li>biegunka;</li>
                            <li>napady padaczkowe.</li>
                        </ul>
                        Wszystkie powyższe objawy mogą znacząco wpłynąć na jakość Twojego życia oraz produktywność,
                        dlatego nie należy ich lekceważyć.
                        Warto jak najszybciej skonsultować się z najbliższym specjalistą, najlepiej neurologiem, który
                        pomoże podjąć odpowiednie kroki w
                        kierunku diagnozy i leczenia. Mapę placówek, w których możesz rozpocząć diagnostykę oraz
                        leczenie RRMS znajdziesz <a className="source" href="/mapa"><b>tutaj</b></a>.
                        Pamiętaj, że troska o swoje zdrowie to najważniejszy krok w kierunku pełniejszego i
                        szczęśliwszego życia.

                        <div className="contentDivTitle">
                            <h3>Sprawdź swoją wiedzę z tego artykułu i rozwiąż <a className="source" href="/objawy/quiz"><u>QUIZ</u></a>!</h3>
                        </div>
                    </p>
                </div>
                {/*<div className="line"></div>*/}
            </div>
        </>
    );
}

export default Symptoms;