import React, {useRef, useState} from "react";
import '../../index.css';
import { treatmentQuestions } from "./treatmentQuestions";


const TreatmentQuiz = () => {
    let [index, setIndex] = useState(0);
    let [question, setQuestion] = useState(treatmentQuestions[index]);
    let [lock, setLock] = useState(false);
    let [score, setScore] = useState(0);
    let [result, setResult] = useState(false);

    let Option1 = useRef(null);
    let Option2 = useRef(null);
    let Option3 = useRef(null);
    let Option4 = useRef(null);

    let arr_option = [Option1, Option2, Option3, Option4];

    let lastQuestion = index === treatmentQuestions.length - 1;
    const buttonText = lastQuestion ? 'Zobacz wynik' : 'Następne pytanie';

    const checkAnswer = (e, ans) => {
        if (lock === false) {
            if (question.ans === ans) {
                e.target.classList.add("correct");
                setLock(true);
                setScore(prev => prev + 1);
            }
            else {
                e.target.classList.add("wrong");
                setLock(true);
                arr_option[question.ans - 1].current.classList.add("correct");
            }
        }
    }

    const nextQuestion = () => {
        if (lock === true) {
            if (index === treatmentQuestions.length - 1) {
                setResult(true);
                return 0;
            }

            setIndex(++index);
            setQuestion(treatmentQuestions[index]);
            setLock(false);
            arr_option.map((option) => {
                option.current.classList.remove("wrong");
                option.current.classList.remove("correct");
                return null;
            })
        }
    }

    const resetButton = () => {
        setIndex(0);
        setQuestion(treatmentQuestions[0]);
        setScore(0);
        setLock(false);
        setResult(false);
    }

    return(
        <>
            <div className="headDiv">
                <h1 className="mainTitle">Quiz</h1>
                <h2 className="mainSubtitle">Spróbuj rozwiązać quiz dotyczący leczenia RRMS</h2>
            </div>
            <div className="quiz-container">
                <h1>QUIZ: leczenie RRMS</h1>
                <div className="lineQuiz"></div>
                {result ? <></> : <>
                    <h2>{index + 1}. {question.question}</h2>
                    <ul>
                        <li ref={Option1} onClick={(e) => {
                            checkAnswer(e, 1);
                        }}>{question.option1}</li>
                        <li ref={Option2} onClick={(e) => {
                            checkAnswer(e, 2);
                        }}>{question.option2}</li>
                        <li ref={Option3} onClick={(e) => {
                            checkAnswer(e, 3);
                        }}>{question.option3}</li>
                        <li ref={Option4} onClick={(e) => {
                            checkAnswer(e, 4);
                        }}>{question.option4}</li>
                    </ul>
                    <button onClick={nextQuestion}>{buttonText}</button>
                    <div className="quiz-index">{index + 1} pytanie z {treatmentQuestions.length}</div>
                </>}
                {result ? <>
                    <h2 className="resultPoints">Zdobyłeś {score} pkt. na {treatmentQuestions.length} możliwych.</h2>
                    <button onClick={resetButton}>Spróbuj ponownie!</button>
                </> : <></>}


            </div>
        </>
    );
}

export default TreatmentQuiz;