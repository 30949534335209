export const symptomsQuestions = [
    {
        question: "Która postać stwardnia rozsianego jest najczęściej diagnozowana?",
        option1: "rzutowo-remisyjna",
        option2: "pierwotnie postępująca",
        option3: "wtórnie postępująca",
        option4: "żadna z powyższych",
        ans: 1,
    },
    {
        question: "Ile procent zdiagnozowanych pacjentów choruje na rzutowo-remisyjną postać SM?",
        option1: "25-30%",
        option2: "55-60%",
        option3: "70-75%",
        option4: "80-85%",
        ans: 4,
    },
    {
        question: "RRMS charakteryzuje się występowaniem symptomów, które utrzymują się dłużej niż…",
        option1: "12 godzin",
        option2: "24 godziny",
        option3: "36 godzin",
        option4: "48 godzin",
        ans: 2,
    },
    {
        question: "Które z objawów występują więcej niż w 50% przypadków?",
        option1: "zmęczenie, depresja, swędzenie",
        option2: "skurcze mięśni, zawroty głowy, utrata słuchu",
        option3: "zmęczenie, zaburzenia równowagi, drętwienie kończyn",
        option4: "ból, biegunka, nudności",
        ans: 3,
    },
    {
        question: "Do jakiego specjalisty najlepiej zgłosić się z objawami RRMS?",
        option1: "do gastrologa",
        option2: "do neurologa",
        option3: "do endokrynologa",
        option4: "do hematologa",
        ans: 2,
    }
]