import "../index.css";

const Sources = () => {
  return(
      <>
          <div className="headDiv">
              <h1 className="mainTitle">Literatura</h1>
              <h2 className="mainSubtitle">Poniżej znajdziesz źródła literaturowe wykorzystane w obrębie
                  przewodnika</h2>
          </div>

          <div className="contentDiv">
              <div className="contentDivTitle">
                  <h3><a className="source" href="/#">Strona główna</a></h3>
              </div>
              <table className="sources-table">
                  <tbody>
                  <tr>
                      <td>[1]</td>
                      <td>
                          Miller D. i in.: Clinically isolated syndromes suggestive of multiple sclerosis, part I:
                          natural history,
                          pathogenesis, diagnosis, and prognosis, The Lancet Neurology, 2005; 4.5: 281-288.
                      </td>
                  </tr>
                  </tbody>
              </table>
              <div className="line"></div>

              <div className="contentDivTitle">
                  <h3><a className="source" href="/objawy">Objawy</a></h3>
              </div>
              <table className="sources-table">
                  <tbody>
                  <tr>
                      <td>[1]</td>
                      <td>
                          Witryna kliniki w Cleveland – <a
                          href="https://my.clevelandclinic.org"><i>www.my.clevelandclinic.org</i></a>
                      </td>
                  </tr>
                  <tr>
                      <td>[2]</td>
                      <td>
                          Portal stowarzyszenia na rzecz osób cierpiących na SM – <a
                          href="https://www.nationalmssociety.org"><i>www.nationalmssociety.org</i></a>
                      </td>
                  </tr>
                  <tr>
                      <td>[3]</td>
                      <td>
                          Portal Johns Hopkins Medicine – <a
                          href="https://www.hopkinsmedicine.org"><i>www.hopkinsmedicine.org</i></a>
                      </td>
                  </tr>
                  <tr>
                      <td>[4]</td>
                      <td>
                          Williams A.E. i in.: Symptoms and association with health outcomes in relapsing-remitting
                          multiple sclerosis: results of a US patient survey, Multiple sclerosis international, 2014.
                      </td>
                  </tr>
                  </tbody>
              </table>
              <div className="line"></div>

              <div className="contentDivTitle">
                  <h3><a className="source" href="/leczenie">Leczenie</a></h3>
              </div>
              <table className="sources-table">
                  <tbody>
                  <tr>
                      <td>[1]</td>
                      <td>
                          Kazibutowska Z.: Diagnostyka, rokowanie i leczenie w stwardnieniu rozsianym w
                          kontekście zagadnień rehabilitacji, Polski Przegląd Neurologiczny, 2008; 4.A: 45-46.
                      </td>
                  </tr>
                  <tr>
                      <td>[2]</td>
                      <td>
                          Witryna internetowa MS One to One – <a
                          href="https://www.msonetoone.pl"><i>www.msonetoone.pl</i></a>
                      </td>
                  </tr>
                  <tr>
                      <td>[3]</td>
                      <td>
                          Informacja Ministra Zdrowia na temat leczenia Stwardnienia Rozsianego z dnia 28 września 2022
                          r.
                      </td>
                  </tr>
                  </tbody>
              </table>
              <div className="line"></div>

              <div className="contentDivTitle">
                  <h3><a className="source" href="/lifestyle">Styl życia</a></h3>
              </div>
              <table className="sources-table">
                  <tbody>
                  <tr>
                      <td>[1]</td>
                      <td>
                          Portal stowarzyszenia na rzecz osób cierpiących na SM – <a
                          href="https://www.nationalmssociety.org"><i>www.nationalmssociety.org</i></a>
                      </td>
                  </tr>
                  <tr>
                      <td>[2]</td>
                      <td>
                          Portal Fizjo Clinica – <a
                          href="https://fizjo-clinica.pl"><i>www.fizjo-clinica.pl</i></a>
                      </td>
                  </tr>
                  <tr>
                      <td>[3]</td>
                      <td>
                          Christogianni A. i in.: Temperature sensitivity in multiple sclerosis: an overview
                          of its impact on sensory and cognitive symptoms, Temperature, 2018; 5.3: 208-223.
                      </td>
                  </tr>
                  <tr>
                      <td>[4]</td>
                      <td>
                          Witryna stowarzyszenia MS Trust – <a
                          href="https://mstrust.org.uk"><i>www.mstrust.org.uk</i></a>
                      </td>
                  </tr>
                  </tbody>
              </table>


          </div>
      </>
  )
}

export default Sources;