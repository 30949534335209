import "../index.css";
import lifestyleFoto from "../img/lifestyle-people.png";

const Lifestyle = () => {
    return (
        <>
            <div className="headDiv">
                <h1 className="mainTitle">Styl życia</h1>
            </div>

            <div className="contentDiv">
                <div className="contentDivText">
                    <img src={lifestyleFoto} alt="czarne sylwetki ćwiczących ludzi" id="lifestyle-foto"/>
                    <p>
                        RRMS wymaga kompleksowego podejścia, które obejmuje nie tylko leczenie farmakologiczne, ale
                        także zmiany w stylu życia. Odpowiednia dieta, regularna aktywność fizyczna, dbanie o sen i
                        redukcja stresu to istotne elementy codziennego funkcjonowania osób z RRMS. Poniżej
                        omówione zostaną te czynniki oraz ich znaczenie w radzeniu sobie z chorobą.


                        <div className="contentDivTitle">
                            <h3>Aktywność fizyczna</h3>
                        </div>
                        Aktywność fizyczna jest niezwykle ważna dla osób z RRMS. Regularne ćwiczenia pomagają w
                        utrzymaniu siły mięśni, poprawiają równowagę i koordynację, a także mogą zmniejszać zmęczenie.
                        Warto jednak pamiętać, że rodzaj aktywności powinien być dostosowany do indywidualnych
                        możliwości i stanu zdrowia, dlatego przed rozpoczęciem nowego programu ćwiczeń dobrze jest
                        skonsultować się z lekarzem lub fizjoterapeutą.

                        <div className="contentDivTitle">
                            <h3>Zdrowa dieta</h3>
                        </div>
                        Dieta odgrywa kluczową rolę w utrzymaniu ogólnego zdrowia i samopoczucia. Zdrowe odżywianie,
                        bogate w witaminy, minerały oraz antyoksydanty, może wspierać układ odpornościowy i pomagać w
                        walce z objawami RRMS. Równie ważne jest odpowiednie nawodnienie organizmu, ponieważ odwodnienie
                        może nasilać zmęczenie i inne objawy związane z
                        chorobą <a className="source" href="/literatura">[1]</a>.

                        <div className="contentDivTitle">
                            <h3>Badania profilaktyczne</h3>
                        </div>
                        Regularne badania profilaktyczne są niezbędne dla monitorowania postępu choroby i dostosowywania
                        leczenia. Systematyczne wizyty u specjalisty oraz wykonywanie zalecanych badań, takich jak
                        rezonans magnetyczny, pomagają w wczesnym wykrywaniu nowych ognisk zapalnych i ocenie
                        skuteczności terapii <a className="source" href="/literatura">[2]</a>.

                        <div className="contentDivTitle">
                            <h3>Zdrowa rutyna</h3>
                        </div>
                        Wyrabianie rutyny, na przykład w zakresie regularnego przyjmowania leków, może znacząco ułatwić
                        życie z RRMS. Stałe pory przyjmowania leków nie tylko pomagają w utrzymaniu ich właściwego
                        poziomu w organizmie, ale również mogą zmniejszać ryzyko zapominania o dawkach, co jest kluczowe
                        dla skuteczności leczenia.

                        <div className="contentDivTitle">
                            <h3>Zdrowy sen</h3>
                        </div>
                        Dbając o odpowiedni sen i odpoczynek, można znacząco wpłynąć na ogólny stan zdrowia. Wypoczęty
                        organizm lepiej radzi sobie z objawami choroby, a odpowiednia ilość snu może również poprawiać
                        nastrój oraz zwiększyć poziom energii w ciągu
                        dnia <a className="source" href="/literatura">[1]</a>.

                        <div className="contentDivTitle">
                            <h3>Odpowiednia temperatura</h3>
                        </div>
                        Temperatura otoczenia również ma znaczenie. Osoby z RRMS mogą być bardziej wrażliwe na zmiany
                        temperatury, co może nasilać objawy. Dlatego należy zadbać o odpowiednie warunki w domu,
                        dostosowane do indywidualnych potrzeb, np. unikać nadmiernego gorąca lub
                        chłodu <a className="source" href="/literatura">[3]</a>.

                        <div className="contentDivTitle">
                            <h3>Redukcja stresu</h3>
                        </div>
                        Ważnym czynnikiem jest również redukcja stresu. Może on nasilać objawy RRMS i prowadzić do
                        częstszych rzutów choroby. Warto więc wprowadzić do codziennego życia techniki relaksacyjne,
                        takie jak medytacja, głębokie oddychanie czy regularne spacery, które pomogą w utrzymaniu
                        równowagi psychicznej <a className="source" href="/literatura">[4]</a>.
                    </p>

                    <div className="contentDivTitle">
                        <h3>Sprawdź swoją wiedzę z tego artykułu i rozwiąż <a className="source" href="/lifestyle/quiz"><u>QUIZ</u></a>!</h3>
                    </div>
                </div>
                {/*<div className="line"></div>*/}
            </div>
        </>
    );
}

export default Lifestyle;