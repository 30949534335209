export const lifestyleQuestions = [
    {
        question: "Na które elementy życia codziennego należy zwracać uwagę w przypadku wystąpienia RRMS?",
        option1: "odpowiednią dietę",
        option2: "regularną aktywność fizyczną",
        option3: "dbanie o sen",
        option4: "wszystkie odpowiedzi są poprawne",
        ans: 4,
    },
    {
        question: "Do czego powinien być dostosowany rodzaj aktywności przy RRMS?",
        option1: "do indywidualnych możliwości i stanu zdrowia",
        option2: "do grafiku w pracy",
        option3: "do rodzaju diety",
        option4: "do cyklu dobowego",
        ans: 1,
    },
    {
        question: "Jakie badanie pomaga we wczesnym wykrywaniu nowych ognisk zapalnych i ocenie skuteczności terapii RRMS?",
        option1: "tomografia komputerowa",
        option2: "rezonans magnetyczny",
        option3: "badanie podstawowej morfologii",
        option4: "ultrasonografia (USG)",
        ans: 2,
    },
    {
        question: "Co warto wprowadzić do życia codziennego w celu redukcji stresu u osób z RRMS?",
        option1: "Regularne spacery",
        option2: "techniki relaksacyjne",
        option3: "medytację i głębokie oddychanie",
        option4: "wszystkie odpowiedzi są prawidłowe",
        ans: 4,
    },
    {
        question: "Zdrowy sen i odpowiednia temperatura - o który z tych czynników należy zadbać przy zachorowaniu na RRMS?",
        option1: "tylko o zdrowy zen",
        option2: "tylko o odpowiednią temperaturę",
        option3: "o zdrowy sen i odpowiednią temperaturę",
        option4: "o żadne z powyższych",
        ans: 3,
    }
]